<template>
    <span v-if="item" class="schedule-col-lesson">
        <div class="text-truncate">
            <span class="font-weight-bold">{{ title }}</span>
        </div>
        <div class="text-truncate subtitle">
            <span>{{ fullName }}</span>
        </div>
        <div class="text-truncate subtitle">
            <span>{{ location }}</span>
        </div>
        <div class="text-right text-uppercase font-weight-bold" style="font-size: 7px">
            {{ item.lesson_type.title }}
        </div>
    </span>
    <div v-else>&nbsp;</div>
</template>

<script>
import _ from 'lodash'

export default {
    name: 'ScheduleColLesson',
    props: {
        item: {
            type: Object,
            default: null
        }
    },
    computed: {
        title() {
            return _.get(this.item, 'course.title')
        },
        location() {
            return _.get(this.item, 'location.title')
        },
        fullName() {
            const teacher = this.item.teacher
            return `${teacher.last_name} ${teacher.first_name.charAt(0)
                .toUpperCase()}. ${teacher.middle_name.charAt(0)
                .toUpperCase()}.`
        }
    }
}
</script>

<style lang="scss">
    .schedule-col-lesson {
        display: inline-grid;
        width: 100%;

        .subtitle {
            font-size: 0.65rem!important;
            line-height: 1rem;
        }
    }
</style>
